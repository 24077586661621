
import { Ref } from '@vue/reactivity'
import { DefaultTable, DefaultTableExposed } from 'apptimizm-ui'
import { TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import axios from '@/axios'
import { Product, ProductMeta } from './types'
import { RouterView } from 'vue-router'

interface TableFilter {
  vendorCode: Ref<string>
  barcode: Ref<string>
  title: Ref<string>
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
  toggle: (item: Product) => void
}

export default ({ table, tableFilter, toggle }: ITableProps) => {
  const productMeta = new ProductMeta()

  const headers: TableHeader[] = [
    { name: 'Код товара', sort: 'vendor_code', minWidth: '160px' },
    { name: 'Штрих-код', sort: 'barcode', minWidth: '300px' },
    { name: 'Название товара', sort: 'title', minWidth: '400px' },
    { name: 'Остаток', sort: 'remainder', minWidth: '116px' },
    { name: 'Склад', sort: 'zone', minWidth: '150px' },
    { name: 'Цена, руб', sort: 'price', minWidth: '154px' }
  ]

  const line = (item: Product) => {
    return (
      <tr onDblclick={() => toggle(item)}>
        <td>{item.vendorCode}</td>
        <td>{item.barcode}</td>
        <td>{item.extendedTitle}</td>
        <td>{item.remainder}</td>
        <td>{item.zone.storageName}</td>
        <td>{item.price}</td>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={productMeta.endpoint}
        defaultFilter={tableFilter.tableParams}
        ref={table}
        line={line}
        itemConverter={(v: Product) => productMeta.load(v)}
        headers={headers}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        scrollPagination={true}
        perPage={20}
      />
    </div>
  )
}
