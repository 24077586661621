
import { defineComponent } from '@vue/runtime-core'
import { DefaultInput, DefaultTableExposed, FormErrors } from 'apptimizm-ui'
import axios from '@/axios'
import { computed, Ref, ref } from 'vue'
import Table from './table.vue'
import { Product, ProductMeta } from './types'
import { store } from '@/store/'
import Modal from './modal'
import { RouterView, useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()

    const productMeta = new ProductMeta()
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const product = ref<Product>(new Product())
    const showPopup = ref(false)
    const formErrors = ref(new FormErrors({}))

    const toggle = (item: null|Product = null) => {
      if (item?.id) {
        router.push({ name: 'products-id', params: { id: store.currentProject.id }, query: { vendorcode: item.vendorCode } })
      }
    }

    const submit = async () => {
      const response = (await axios.patch(
        productMeta.endpoint + product.value.id + `/?project=${store.currentProject.id}`,
        productMeta.dump(product.value)
      )).data

      if (response.errors) formErrors.value = productMeta.errors(response.errors)
      else toggle()
    }

    const isProjectSelected = computed(() => !!store.currentProject.id)

    const tableFilter = {
      vendorCode: ref(''),
      barcode: ref(''),
      title: ref(''),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id
        if (this.vendorCode.value) res.vendor_code = this.vendorCode.value
        if (this.barcode.value) res.barcode = this.barcode.value
        if (this.title.value) res.title = this.title.value

        return res
      }
    }

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Товары</h1>
          <div class="page-top-panel-row mt-5">
            <label>
              <DefaultInput
                class={tableFilter.vendorCode.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Код товара"
                onValueChange={(v: string) => { tableFilter.vendorCode.value = v }}
                modelValue={tableFilter.vendorCode.value}
              />
            </label>
            <label>
              <DefaultInput
                class={tableFilter.barcode.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Штрих-код товара"
                onValueChange={(v: string) => { tableFilter.barcode.value = v }}
                modelValue={tableFilter.barcode.value}
              />
            </label>
            <label>
              <DefaultInput
                class={tableFilter.title.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Название товара"
                onValueChange={(v: string) => { tableFilter.title.value = v }}
                modelValue={tableFilter.title.value}
              />
            </label>
          </div>
        </div>
        <div class="product-content">
          <div class={{
            layout: true,
            'layout--height': route.params.id
          }}>
            {isProjectSelected.value
              ? <Table table={table} tableFilter={tableFilter} toggle={toggle}/>
              : null
            }
          </div>
          <RouterView/>
        </div>
        <div class="bottom-row">
          { table.value && (<p style="margin: auto; margin-left: 0;">Всего: {table.value?.count}</p>)}
        </div>
        {showPopup.value && <Modal toggle={toggle} product={product} submit={submit} formErrors={formErrors}/>}
      </div>
    )
  }
})
